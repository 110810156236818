import { SEO } from "gatsby-theme-core";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@lendex/helper/methods";
import { Layout, HeaderOne, FooterOne } from "gatsby-theme-layout";
import {
    Funfact,
    Testimonial,
    ClientOne,
    AboutUsOne,
    ResumeTab,
    ResumeItem,
    PageHeader01,
} from "gatsby-theme-container";

const AboutPage = ({ data }) => {
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO pageName="About Us" />
            <HeaderOne data={{ ...data.header, ...data.navigation }} />
            <PageHeader01 data={content?.["page-header-section"]} />
            <AboutUsOne data={content?.["about-us-section"]} />
            <ResumeTab>
                <ResumeItem data={content?.["education-section"]} />
                <ResumeItem data={content?.["experience-section"]} />
            </ResumeTab>
            <Funfact data={content?.["funfact-section"]} />
            <Testimonial data={content?.["testimonial-section"]} />
            <ClientOne data={content?.["favourite-clients-section"]} />
            <FooterOne data={{ ...data.footer, ...data?.contact }} />
        </Layout>
    );
};
export const query = graphql`
    query AboutPageQuery {
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact01
        }
        page(title: { eq: "about" }) {
            content {
                ...PageContent
            }
        }
    }
`;

AboutPage.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({}),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default AboutPage;
